.SingleOngoingOrder__page__block{

        .SingleOngoingOrder__page__tab {
            .react-tabs__tab-list {
                border: none;
                text-align: center;
                display: flex;
                justify-content: center;
                gap: 80px;
                margin-bottom: 40px;
    
                li {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    color: var(--grey-700, #344054);
    
                    /* 16px/Medium */
                    font-family: 'Poppins';
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    position: relative;
    
                    span {
                        width: 10px;
                        height: 10px;
                        background: #888686;
                        border-radius: 50%;
                        position: relative;
                        display: block;
                        
    
                        &::before {
                            content: "";
                            border: 2px solid #888686;
                            height: 17px;
                            width: 17px;
                            top: -5px;
                            left: -5px;
                            border-radius: 50%;
                            position: absolute;
                            
                        }
                    }
    
                    &:before {
                        content: "";
                        height: 2px;
                        width: 60px;
                        left: -72px;
                        bottom:20px;
                        background-color: #888686;
                        position: absolute;
                        top: 18px;
                    }
    
                    &:first-child {
                        &::before {
                            content: none;
                        }
                    }
    
                    &.react-tabs__tab--selected {
                        color: #168952;
                        background-color: transparent;
                        span {
                            &::before {
                                border-color: #168952;
                            }
                        }
                    }
                }
    
            }
    
            .react-tabs__tab {
                position: relative;
                border: none;
            }
        }
    }
    .SingleOngoingOrder__page__ShipAddressSec{
        background-color: #FFFFFF;
        margin: 1rem 0;
        padding: 1rem;
    }
    .SingleOngoingOrder__page__RewardSec{
        background-color: #D71513;
        padding:1rem;
        color:#FFFFFF;
        font-weight: 500;
        margin-top: 0.5rem;
    }
    .SingleOngoingOrder__page__Header{
        font-family: "Poppins";
        font-size: 14px;
    }
    .SingleOngoingOrder__page__Title{
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 500;
    }
    .SingleOngoingOrder__page__Desc{
        color:#767676;
        font-family: "Poppins";
                font-size: 13px;
    }
    .SingleOngoingOrder__page__ContactDesc{
        display:flex;
        align-items: center;
        gap:0.1rem;
        font-family: "Poppins";
       
    }
    .SingleOngoingOrder__page__ContactDesc__Header {
        color: #767676;
        
    }
    .SingleOngoingOrder_Page_Wrapper{
        display:flex;
        justify-content: space-between;
        font-family: "Poppins";
    }
    .SingleOngoingOrder__page__Value{
        font-weight: 500;
        font-size: 15px;
        font-family: "Poppins";
    }
    .SingleOngoingOrder_Page_Wrapper__Head{
                font-family: "Poppins";
                font-size:13px;
    }
    .SingleOngoingOrder_Page_Wrapper__Link{
        color:#D71513;
        font-size: 14px;
        cursor: pointer;

    }
    .Payment__Icon{
        width:10%;
    }
    .active{
    color: #168952; 
    .react-tabs {
            color: #168952;
            background-color: transparent;
    
            span {
                &::before {
                    border-color: #168952;
                }
            }
        }
    }
  .Delivered__OrderPage__Block{
    background-color: #168952;
    display: flex;
    gap:1rem;
    padding: 1rem;
    align-items: flex-start;
    color: #FFFFFF;
    margin: 2rem 0 1rem 0;
  }
  .Delivered__OrderPage__Rating{
    padding: 1rem;
    color: #D71513;
    display: flex;
    justify-content: center;
  }
  .SingleOngoingOrder__page__DescValue{
    font-size: 13px;
    font-family: "Poppins";
    align-items: flex-end;
  }
  .SingleOngoingOrder__page-e-invoice {
    overflow: hidden;
      border-radius: var(--br-5xs);
      background-color: var(--base-white);
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.03);
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1px;
      font-size: var(--text-md-semibold-size);
      color: var(--base-black);
      font-family: var(--text-md-semibold);
      align-self: stretch;

      .full-name-parentAccount {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: var(--gap-sm);
        }

        .full-nameAccount {
    align-self: stretch;
    position: relative;
    text-transform: capitalize;
    font-size: 12px;
  }
    .full-nameAccountEmail {
      align-self: stretch;
      position: relative;
    font-size: 12px;
    }
    }
   @media screen and (max-width: 970px) {


       .react-tabs__tab-list {
        flex-direction: column !important;
        gap:1rem !important;
        li {
            span{
                

                &::before {
                       
                        height: 18px;
                        width: 18px !important;
                        top: -3px !important;
                        left: -4px;
                        
                
                    }
            }
            &:before {
                height: 21px !important;
                    width: 2px !important;
                left: 14px !important;
                    position: absolute !important;
                    top: -21px !important;
            }
        }
       }

   }
   .Invoice__Btn{
    color:#D71513;
    font-family: "Poppins";
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap:0.2rem
   }

   @media screen and (max-width:800px){
    .PCoin_Icon{
        width:30px
    }
   }