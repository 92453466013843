.NoOrder__Card{
    text-align: center;
    margin-top: 3rem;
    .NoOrder__CardButton{
     border:1px solid #D71513;
     padding:0.2rem 3rem;
     color:#D71513;
     border-radius: 5px;
     cursor: pointer;
     margin-top: 1rem;
    }
    img{
        margin-bottom: 2rem;
    }
    h3{
    font-family: 'Poppins';
    }
    p{
        color:#767676;
    }
}

