.ProductTracker__Inner__Cont{
    
    // justify-content: space-between;
    // gap:1rem;
    // margin-top: 1.5rem;

    margin: 1.5rem 0;
    .ProductTrackker__IconText{
        margin-top:-6px;

    }
    .Progress__Tracker__Line{
    background-color: #767676;
    width: 2px;
    height: 50px;
    opacity: 1;
    margin-left:7px;
    margin-top:0;
    margin-bottom: 0;
    
    }
    .active__Line{
    background-color: #007161;
    width: 2px;
        height: 50px;
        opacity: 1;
        margin-left: 7px;
            margin-top: 0;
            margin-bottom: 0;
    
    }
    .Product__Tracker__Stage{
        font-family: "Poppins";
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        margin-left:3px;
        
    }
    .active__State{
        color:#168952;
        font-family: "Poppins";
            font-size: 12px;
            text-align: center;
                    font-weight: 500;
                    margin-left: 3px;
    }
    
   
}
p{
    margin:0;
}

// .ProductTracker__Inner__Cont{
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
// }
// .ProductTracker__InnerInner__Cont{
//      position: relative;
//     }
//     .Progress__Tracker__Line {
//         background-color: #767676;
//         width: 2px !important;
//         height: 50px !important;
//         opacity: 1;
//         position: absolute;
//         top:-52px;
//         left:7px;
//         overflow:hidden !important
//     }
//     .active__Line {
//     background-color: #007161 !important;
//     width: 2px !important;
//     height: 50px !important;
//     opacity: 1;
//             position: absolute;
//                 top: -60px;
//                 left: 7px
// }
// .Confirmed__Icon{
//     z-index: 1;
// }
// .toProcessIcon{
//     margin-left:0.2rem !important;
// }
@media screen and (max-width:800px){
    .ProductTracker__Inner__Cont{
        img{
            width:30px;
        }

    }
        .ProductTracker__Inner__Cont .Progress__Tracker__Line{
                margin-left: 13px;
        }
}



    
