
// Header-styling
.header__container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 80px;
  width: 100%;
  border-bottom: 1px solid var(--grey-200, #EAECF0);
  &__left___block{
    display: flex;
    gap: 65px;
    align-items: center;
    flex: 2 1 0;
    // &__logo{
      
    // }
    &__search--block{
      position: relative;
      flex: 2 1 0;
      [class*="-control"]{
        min-height: 44px;
      }
      [class*="-control"]:hover{
        border-color: #D71513;
      }
      [class*="-IndicatorsContainer"]{
        display: none;
      }
     
    }
    // &__search--icon{
    //   position: absolute;
    //   left: 14px;
    //   top: 50%;
    //   transform: translateY(-9px);
    //   font-size: 20px;
    // }
    // &__search--input{
    //   display: flex;
    //   padding: 10px 14px 10px 40px;
    //   align-items: center;
    //   gap: 8px;
    //   align-self: stretch;
    //   border-radius: 8px;
    //   width: 100%;
    //   max-width: 570px;
    //   border: 1px solid var(--grey-300, #D0D5DD);
    //   background: var(--base-white, #FFF);
    //   box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    //   &:focus-visible,
    //   &:active,
    //   &:focus{
    //     outline: none;
    //     border: 1px solid var(--grey-300, #D0D5DD);
    //     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    //   }
    // }
  }
 &__right___block{
    display: flex;
    gap: 10px;
    align-items: center;
    flex: 1 1 0;
    justify-content: end;
    .header-icon{
      cursor: pointer;
      svg{
        font-size: 25px;
      }
      padding:8px;
    }
        .header-icon-notification {
          cursor: pointer;
    
          svg {
            font-size: 25px;
          }
    
          padding:12px;
        }
  .profileDropdown{
    &__toggle__btn{
      padding: 0;
      background: transparent;
      border: none;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      &:active{
        background: transparent !important;
      }
      &:hover{
        background: transparent !important;
      }
    }
    .dropdown-menu{
      min-width: 240px;
      border-radius: 8px;
      border: 1px solid var(--grey-200, #EAECF0);
      background: var(--base-white, #FFF);
      /* Shadow/lg */
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    }
    .profile-header {
      display: flex;
      height: fit-content;
      align-items: center;
      gap: 10px;
      padding: 12px 16px;
      border-bottom: 1px solid var(--grey-200, #EAECF0);
    }
    
    .header-profile-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    
    .profile-header-img {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 50%;
    }
    .default-profile-thumb{
      background: #F2E6E6;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 4px solid #DCE8FF;
      h3{
        margin: 0;
        color: var(--Primary---D71513, #D71513);
        font-family: 'Poppins';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .profile-header-img.default-profile-thumb{
      h3{
        font-size: 20px;
      }
    }
    .profile-name {
      color: var(--grey-700, #344054);
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      margin-bottom: 0;
    }
    
    .profile-name-email {
      display: flex;
      flex-direction: column;
      gap: 0px;
      justify-content: flex-start;
      margin-bottom: 0;
    }
    
    .profile-email {
      color: var(--grey-500, #667085);
      /* Text xs/Regular */
      font-family: 'Poppins';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      margin-bottom: 0;
    }
    .profile-link {
      display: flex;
      align-items: center;
      gap: 12px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: var(--grey-700, #344054);
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 10px 16px;
      svg{
        font-size: 16px;
      }
      &.border__bottom{
        border-bottom: 1px solid var(--grey-200, #EAECF0);
      }
      &__v2{
        color: var(--Primary---D71513, #D71513);
        &:active{
          color: var(--Primary---D71513, #D71513);
        }
      }
      &:active{
        color: var(--grey-700, #344054);
      }
    }
  }
}
.cart-bag-block{
  position: relative;
}

.cart-bag-badge{
  position: absolute;
  top: -8px;
  right: -6px;
  color: #D71513;
  height: 20px;
  width: 20px;
  background: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: wheat;
  font-size: 12px;
}}

@media screen and (max-width: 950px ) {
  .header__container{
    &__left___block{
      flex: 1.6 1;
    }
  }
}

@media screen and (max-width: 900px ) {
  .header__container{
    &__left___block{
      flex: none;
      &__search--block{
        display: none;
      }
      &__logo{
        width: 125px;
      }
    }
    &__right___block {
      .header-icon{
        cursor: pointer;
        svg{
          font-size: 20px;
        }
      }
    }
    .header-sign-in-btn,
    .header-sign-up-btn{
      padding: 7px 10px;
    }
  }
 
}

.header-btn-group{
  display: flex;
  gap: 8px;
  align-items: center;
}
.header-sign-in-btn{
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--grey-500, #667085);
  background: var(--base-white, #FFF);
  color: #667085;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-family: ''Poppins'';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 44px;
}

.header-sign-up-btn{
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  height: 44px;
  color: #FFF;
  border: 1px solid var(--primary-d-71513, #D71513);
  background: var(--primary-d-71513, #D71513);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-family: ''Poppins'';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.header-icon-active{
  border: 4px solid #f1f0f0;
  padding: 8px;
  border-radius: 50%;
  svg {
      font-size: 25px;
    }
  
}
.Header__Icon{
  width:20
}
@media screen and (max-width: 980px) {
  .header__container{
    padding: 15px 10px;
  }
}

@media screen and (max-width: 580px ) {
  .header__container{
    &__right___block {
      gap: 0px;
    }
  }
 
}
@media screen and (max-width:420px){
  // .header-icon {
  //     display: none !important;
  //   }
  
  //   .header-icon-notification {
  //     display: none !important;
  //   }
  .header__container{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
}