.dot-icon1Cat {
    position: relative;
    width: 10px;
    height: 10px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .category-childCat {
    
    width: 25px;
    height: auto;
  }
  .categoryCat {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: fit-content;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .text9Cat {
    position: relative;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    font-size: 16px;
  }
  .content4Cat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .text10Cat {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  .badge1Cat {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--grey-700);
  }
  .chevron-down-icon1Cat {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .nav-item-baseCat {
    border-radius: var(--br-7xs);
    background-color: var(--grey-50);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .bar-chart-01-icon1Cat {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text11Cat {
    position: relative;
    font-size: .9rem;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: var(--grey-700);
    text-align: left;
  }

  .text12Cat {
    position: relative;
    font-size: var(--text-xs-medium-size);
    line-height: 18px;
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--grey-700);
    text-align: center;
  }
  .badge2Cat {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
  }
  .nav-item-base1Cat {
    cursor: pointer;
    border: none;
    padding: var(--padding-5xs) var(--padding-xs);
    background-color: var(--base-white);
    flex: 1;
    border-radius: var(--br-7xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .nav-item-base-parentCat {
    margin: 0;
    width: 473px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .p-coin-iconCat {
    position: relative;
    width: 22px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .badge6Cat {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
  }
  .nav-item-base5Cat {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    border: 1px solid var(--gray-500);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .navigationCat {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
    color: var(--grey-700);
    font-family: 'Poppins', sans-serif;
  }
  .container1Cat {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base) 20px;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: left;
    color: var(--grey-900);
    font-family: var(--text-md-semibold);
  }
  

  @media screen and (max-width: 420px) {
    .nav-item-baseCat {
      padding: 0px;
    }
  }