.wishlist__Page{
        margin:1rem 3rem;
        .wishlistPage_EmptyContainer{
                text-align: center;
                width:375px;
                margin:1rem auto;
                align-items: center;
        }
        .WishlistCard__Container{
                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                // gap:1rem;
                align-self: stretch;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: start;
                        gap: var(--gap-13xl);
                        font-size: var(--px-medium-size);
                        color: var(--primary-d71513);
                        max-width: 1200px;
                        margin: 0 auto;
                
        }
        .wishlistPage__Head{
            font-family: 'Poppins';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
        }
        .wishlistPage__EmptyHead{
                color:#D71513;
                font-family: 'Poppins';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

        }

        .wishlistPage__EmptyDesc{
                color:black;
                font-family: 'Poppins';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 200;
                        line-height: normal;
        }

        .wishlistPage__ItemCount{
                        font-family: 'Poppins';
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 200;
                        line-height: normal;
                        color:grey;

        }
        .wishlistPage__EmptyBtn{
                color: #D71513;
                        border: 1.3px solid #D71513;
                        width: 50%;
                        border-radius: 8px;
                        cursor: pointer;
                        padding:0.5rem 2rem;
                        margin:1rem;
        }

}

@media screen and (max-width: 600px) {
.WishlistCard__Container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                width: 100%;
        }
       .wishlistPage_EmptyContainer{
                width:100% !important;
                text-align: center;
                img{
                        width: 100%;
                }
       } 

}