.select-delivery-addressDeliveryAddressListing {
  margin: 0;
  position: relative;
  font-size: 24px;
  font-weight: 500;
  font-family: inherit;
  margin-bottom: 15px;
}
.label-text2DeliveryAddressListing {
  position: relative;
  font-size: var(--m3-label-large-size);
  font-weight: 500;
  font-family: var(--text-md-semibold);
  color: var(--color-darkslategray-200);
  text-align: center;
}
.state-layer2DeliveryAddressListing {
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  align-items: center;
  justify-content: center;
}
.buttonDeliveryAddressListing {
  cursor: pointer;
  border: 1px solid var(--color-darkslategray-200);
  padding: 0;
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  height: 38px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.select-delivery-address-parentDeliveryAddressListing {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--px-medium1-size);
  color: var(--base-black);
}
.default-addressDeliveryAddressListing {
  position: relative;
  font-weight: 500;
  display: inline-block;
}

.frame-wrapperDeliveryAddressListing {
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  align-items: flex-start;
  justify-content: flex-start;
}
.sinan-aftharDeliveryAddressListing {
  align-self: stretch;
  position: relative;
  font-size: var(--px-medium-size);
  font-weight: 500;
  color: var(--base-black);
  text-transform: capitalize;
}
.jalan-tun-sambanthanDeliveryAddressListing {
  margin: 0;
}
.jalan-tun-sambanthan-containerDeliveryAddressListing {
  position: relative;
}
.spanDeliveryAddressListing {
  color: var(--base-black);
}
.removeDeliveryAddressListing {
  position: relative;
  font-size: var(--m3-label-large-size);
  font-weight: 500;
  font-family: var(--text-md-semibold);
  color: var(--color-darkslategray-200);
  text-align: left;
}
.remove-wrapperDeliveryAddressListing {
  cursor: pointer;
  border: 1px solid var(--color-darkslategray-200);
  padding: var(--padding-5xs) var(--padding-mini);
  background-color: var(--m3-sys-light-on-primary);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.editDeliveryAddressListing {
  position: relative;
  font-size: var(--m3-label-large-size);
  font-weight: 500;
  font-family: var(--text-md-semibold);
  color: var(--color-darkslategray-200);
  text-align: right;
}
.frame-parent7DeliveryAddressListing {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.sinan-afthar-parentDeliveryAddressListing {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.frame-parent6DeliveryAddressListing {
  background-color: var(--m3-sys-light-on-primary);
  border: 1px solid var(--border-ededed);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--m3-label-large-size);
  width: 100%;
  margin-top: 15px;
}
.default-address-parentDeliveryAddressListing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  width: 100%;
}
.frame-child1DeliveryAddressListing {
  border-radius: var(--br-981xl);
  border: 1px solid var(--grey-767676);
  box-sizing: border-box;
  width: 17px;
  height: 17px;
}
.other-address-parentDeliveryAddressListing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  width: 100%;
}
.add-new-addressDeliveryAddressListing {
  position: relative;
  font-size: var(--px-medium-size);
  font-weight: 500;
  font-family: var(--text-md-semibold);
  color: var(--primary-d71513);
  text-align: left;
}
.add-new-address-wrapperDeliveryAddressListing {
  cursor: pointer;
  border: 1px solid var(--grey-767676);
  padding: var(--padding-sm) var(--padding-5xl);
  background-color: var(--m3-sys-light-on-primary);
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  height: 61px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 30px 0;
}
.frame-parent5DeliveryAddressListing {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--text-md-semibold-size);
  color: var(--grey-767676);
  font-family: var(--text-md-semibold);
}

.address {
  &__block {
    width: 100%;
  }
  &__title {
    &__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      h3 {
        color: var(--gray-800, #262626);
        /* 18px/Medium */
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
      }
      button {
        color: var(--Primary---D71513, #d71513);

        /* 16px/Medium */
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  &__item__block {
    margin-top: 15px;
  }
}

@media screen and (max-width: 420px) {
  .default-address-parentDeliveryAddressListing {
    align-self: stretch;
    width: auto;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .default-addressDeliveryAddressListing {
    padding-left: 0px;
  }

  .select-delivery-address-parentDeliveryAddressListing {
    gap: 15px;
  }

  .parentDeliveryAddressListing,
  .default-address-parentDeliveryAddressListing {
    padding-left: 0;
    align-items: start;
  }

  .frame-parent5DeliveryAddressListing {
    padding-inline: 0px;
    justify-content: center;
  }
}

@media screen and (max-width: 530px) {
  .select-delivery-address-parentDeliveryAddressListing {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 370px) {
  .address__title__block {
    h3 {
      font-size: 100%;
    }
    button {
      font-size: 100%;
    }
  }
}
