.Notification__Main__Container {
  position: absolute;
  background-color: #f9f8f8;
  padding: 2rem;
  z-index: 1001 !important;
  right: 90px;
  top: 72px;
  width: 370px;
  height: 440px;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 5px;

  .Notification__Container {
    margin-bottom: 1rem;
    border-bottom: 1px solid rgb(232, 231, 231);
    padding-bottom: 1rem;
  }
  .Notification__Heading {
    color: #168952;
    font-family: "Poppins";
    margin: 0;
    font-size: 14px;
    font-weight: 300;
  }
  .Notification__Heading__In__Red {
    color: #d71513;
    font-family: "Poppins";
    margin: 0;
    font-size: 15px;
    font-weight: 600;
  }
  .Notification__Content {
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 450;
    margin-top: 0.5rem;
  }
  .Notification__Date {
    font-family: "Poppins";
    font-size: 11px;
    color: grey;
    margin-top: 1rem;
  }
  .Notification__Btn {
    color: #d71513;
    font-family: "Poppins";
    font-weight: 500;
    padding: 0%;
  }
  .Empty__Notification__Container {
    text-align: center;
  }
  .NotifyBell__Emoji {
    margin-top: 3rem;
  }
  .Empty__Notification__Text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
    font-weight: 700;
    text-align: center;
    color: #d71513;
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 930px) {
  .Notification__Main__Container {
    position: absolute;
    background-color: #fbfafa;
    padding: 1rem;
    z-index: 1;
    right: 53px;
    width: 40%;
  }
}
@media screen and (max-width: 500px) {
  .Notification__Main__Container {
    position: absolute;
    background-color: #ededed;
    padding: 1rem;
    z-index: 1001 !important;
    right: 20px;
    width: 80%;
  }
}
