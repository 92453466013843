.catalogue__page {
  &__block {
    padding-top: 40px;
    .brands__info__block {
      display: flex;
      text-align: center;
      /* 20px/Medium */
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &--name {
        color: var(--base-black, #1c1c1c);
      }
      &--itemsCount {
        color: var(--Grey---767676, #888686);
      }
      &.promo__page__info__block {
        padding: 0px 0px 10px 0px;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
      }
    }
    .promo__banner__block {
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
    .filter__btn__block {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 40px;
      .filter__btn {
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--Primary---D71513, #d71513);
        width: 100%;
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--base-white, #fff);

        /* Text md/SemiBold */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
  }
  &__main__block {
    padding-top: 30px;
    display: flex;
  }
  &__sidebar {
    padding-right: 15px;
    width: 280px;
    border-right: 1px solid #e6e6e6;
    &.display__none {
      display: none;
    }
  }
  &__category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 20px 0px 10px 0px;
    border-bottom: 1px solid #e6e6e6;
    h3 {
      margin-bottom: 0;
      color: var(--base-black, #1c1c1c);
      /* 18px/Medium */
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .filter__toggle__btn {
      display: flex;
      padding: 7px 14px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid var(--Grey---767676, #888686);
      background: #fff;
      color: var(--base-black, #1c1c1c);
      text-align: center;

      /* 14px/Medium */
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__filter {
    &__category {
      padding-bottom: 40px;
      border-bottom: 1px solid #e6e6e6;
      h3 {
        color: var(--base-black, #1c1c1c);
        /* 16px/Medium */
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
      }
      &--v2 {
        margin-top: 25px;
      }
    }
    &__category__item {
      display: flex;
      gap: 10px;
      margin-bottom: 15px;
      .form-check-input {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-top: 0;
        border: 1px solid var(--Grey---767676, #888686);
        background: #fff;
        &:focus {
          box-shadow: none;
          border: 1px solid var(--Grey---767676, #888686);
        }
        &:checked {
          border: 1px solid var(--primary-500, #fa5000);
          background: var(--primary-25, #fff8f5) !important;
          box-shadow: none;
        }
        &:checked[type="checkbox"] {
          --bs-form-check-bg-image: url("../../../../images/homepage/check.png") !important;
          background-image: url("../../../../images/homepage/check.png") !important;
          background-position: center;
          position: relative;
          background-position: 1px !important;
          background-repeat: no-repeat !important;
        }
        &:checked + label {
          color: var(--Primary---D71513, #d71513);
        }
      }
      .form-check-label {
        color: var(--Grey---767676, #888686);
        /* 14px/Regular */
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.sorting__filter__block {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--grey-300, #d0d5dd);
  background: var(--base-white, #fff);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  .form-label {
    margin-bottom: 0;
    color: var(--grey-500, #667085);
    /* Text md/Regular */
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    min-width: 65px;
  }
  select {
    border: none;
    min-width: 120px;
    &:focus {
      box-shadow: none;
    }
  }
}

.productFilter__drawer__block__navbar {
  padding: 15px;
  width: 100% !important;
  overflow: scroll !important;
  z-index: 1000 !important;
  .bannerType-menu-icon {
    display: flex;
    margin-left: auto;
    font-size: 25px;
    stroke-width: 1.5px;
    stroke: var(--grey-500, #667085);
  }
  .navigation__block__navbar {
    flex-direction: column;
  }
  .catalogue__page__sidebar {
    border: none;
    width: 100%;
  }
}

.catalogue__page__products__block {
  padding-left: 30px;
  width: 100%;
}
.catalogue__page__banner {
  width: 100%;
  height: auto;
  margin-bottom: 1.7rem;
}

@media screen and (max-width: 900px) {
  .catalogue__page__products__block {
    padding-left: 0px;
  }
}
@media screen and (max-width: 450px) {
  .promo__banner__block {
    img {
      max-width: 100% !important;
      width: 100% !important;
      // height: 170px !important;
      height: 100%;
    }
  }
}
