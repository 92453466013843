


body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.grecaptcha-badge {
  visibility: hidden !important;
}

button {
  background: transparent;
  border: none;
  font-family: 'Poppins', 'sans-serif';
}

code {
  font-family: 'Poppins', 'sans-serif', 'Monaco', 'Consolas', 'Courier New',
    'monospace';
}

.toast__block{
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  position: relative;
  button{
    position: absolute;
    top: -7px;
    right: -15px;
  }
  h4{
    color: var(--base-white, #FFF);
    /* Text sm/SemiBold */ 
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  p{
    margin-bottom: 0;
    /* Text sm/Regular */
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  &__icon{
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 1000px;
    background: var(--base-white, #FFF);
  }
}

.form-check-input:checked {
  background-color: #D71513 !important;
  border-color: #D71513 !important;
}

.form-check-input:focus{
  box-shadow: none !important;
}

.loader-btn {
  border: 3px solid rgb(255 255 255 / 91%);
  border-top: 3px solid #ffffffa8;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  animation: spin 2s linear infinite;
}
.button--group{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
@keyframes spin {
  0% { transform: rotate(150deg); }
  100% { transform: rotate(360deg); }
}
