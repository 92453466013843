.navigation__block {
  display: flex;
  justify-content: space-between;
  padding: 12px 30px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  position: relative;
  &__navbar {
    display: flex;
    gap: 8px;
    .nav-item {
      padding: 8px 12px;
      color: var(--grey-700, #344054);
      /* 16px/Medium */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-bottom: 2px solid transparent;
      &.promo-nav-active {
        border-color: #d71513;
        color: #d71513;
      }
    }

    .nav-item {
      cursor: pointer;
    }
    .redumption__btn {
      border-radius: 6px;
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      border: 1px solid var(--gray-500, #737373);
      background: var(--base-white, #fff);
      &.redumption__btn__active {
        border-color: #d71513;
        background: var(--Primary---D71513, #d71513);
        color: #fff;
      }
    }
  }
  &__search--block {
    position: relative;
    flex: 2 1 0;
  }
  &__search--icon {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-9px);
    font-size: 20px;
  }
  &__search--input {
    display: flex;
    padding: 10px 14px 10px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    width: 100%;
    max-width: 255px;
    border: 1px solid var(--grey-300, #d0d5dd);
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    &:focus-visible,
    &:active,
    &:focus {
      outline: none;
      border: 1px solid var(--grey-300, #d0d5dd);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
}

.navigation__drawer__block__navbar {
  padding: 15px;
  // .bannerType-menu-icon {
  //   display: flex;
  //   margin-left: auto;
  //   font-size: 16px;
  //   stroke-width: 1.5px;
  //   stroke: var(--grey-500, #667085);
  //   width: 23px !important;
  //   height: 23px !important;
  // }
  .navigation__block__navbar {
    flex-direction: column;
  }
}
.bannerType-menu-icon {
  display: flex;
  margin-left: auto;
  font-size: 16px;
  stroke-width: 1.5px;
  stroke: var(--grey-500, #667085);
  width: 23px !important;
  height: 23px !important;
}
.navigation__Main__Container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Available__Coin__Container {
  background-color: #fff9da;
  padding: 4px 6px;
  border-radius: 7px;
  display: flex;
  gap: 0.7rem;
}
.Available__Coin__Container__No {
  font-family: "Poppins";
  font-weight: 700;
  color: #d71513;
  margin: 0;
}
.Available__Coin__Container__Text {
  font-family: "Poppins";
  color: #737373;
  font-size: 13px;
  margin: 0;
}

@media screen and (max-width: 980px) {
  .navigation__block {
    padding: 15px;
    gap: 10px;
  }
}
@media screen and (max-width: 450px) {
  .Search__Container {
    width: 300px;
  }
}
@media screen and (max-width: 400px) {
  .Search__Container {
    width: 255px;
  }
}
