.wishlist__Card {
  position: relative;
  width: 260px;
  height: 400px;

  box-shadow: 2px 2px 18px -11px rgba(196, 196, 196, 0.78);
  margin-top: 2rem;
  cursor: pointer;
  text-decoration: none;
  .wishlistCard__Details {
    padding: 15px;
  }
  .wishlistCard__Title {
    color: black;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.7rem;
  }
  .wishlist__Price {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 0.7rem;
  }
  .old__Price {
    color: grey;
    font-family: "Poppins";
    font-size: 11px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-top: 2px !important;
    margin-bottom: 0px;
  }
  .cut__Line {
    width: 100%;
  }

  .discount__Price {
    color: black;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
  }
  .discount__Precent {
    color: #eb5806;
    font-family: "Poppins";
    font-size: 11px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-top: 2px !important;

    margin-bottom: 0px;
  }
  .wishlistCard_AddBtn {
    color: #d71513;
    border: 1px solid #d71513;
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    padding: 0.1rem 0;
  }
  .wishlist__Rating {
    margin-bottom: 7px;
    color: green;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .wishlist__ReviewCount {
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
  }
  .wishlistCard_AddedBtn {
    color: #767676;
    border: 1px solid #767676;
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    padding: 0.1rem 0;
  }
  .wishlistCard__CrossIcn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  // .Image__Container{
  //     width: 100%;
  // height: 158px;
  //     background-color: #767676;
  // }
  .wishlistCard__Image {
    width: 100%;
    height: 200px;
  }
}
