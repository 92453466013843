
.modal-dialog {
    width:20%;
    position: relative;
.Modal__Last__text {
        color: #D71513;
        font-family: "Poppins";
        font-size: 13px;
        font-weight: 700;
        margin-top: 1rem;
    }

    .Success__Modal__Cross__Icon {
        color: #D71513;
        width:30%;
    }
    .Animation__Container{
        position:absolute;
        text-align: center;
        // height: 50px;
        //     width: 360px !important;
        //     right: 35px !important;
    }

}
@media screen and (max-width:500){
    .modal-dialog{
       .Animation__Container {
        // height: 50px;
width: 100% !important;
height: 100% !important;
}
    }
}