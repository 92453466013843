.OrderCard {
  border: 0.5px solid #ededed;
  padding: 1rem;
  width: 100%;
  background-color: #ffffff;
  margin-top: 1rem;
  cursor: pointer;
  .OrderCard__TopContainer {
    display: flex;
    justify-content: space-between;
  }
  .OrderCard__Bottom__Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .Order__status {
    color: #d71513;
    font-family: "Poppins";
    font-size: "15px";
  }
  .OrderCard__TopRightContainer {
    display: flex;
    gap: 1rem;
  }
  .OrderCard__Banner {
    width: 25%;
  }
  .OrderCard__Desc {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .OrderCard__Header {
    color: #168952;
    font-weight: 300 !important;
    font-size: 16px;
    margin-bottom: 1rem;
    font-weight: 500;
    font-family: "Poppins";
  }
  .Order__Status__In__Green {
    color: #168952;
    font-family: "Poppins";
    font-size: "15px";
  }
  .Ordercard__Title {
    color: black;
    font-weight: 500;
    font-size: 20px;
    font-weight: 500 !important;
    font-family: "Poppins";
    text-transform: capitalize;
  }
  .OrderCard__PriceTag {
    color: black;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: start;
  }
  .OrderCard__Details {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .Product__ColorTitle {
    color: black;
    font-weight: 500;
    font-size: 15px;
  }
  .Product__SizeTitle {
    color: black;
    font-weight: 500;
  }

  .Product__Color {
    color: #747474;
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
  .Product__Size {
    color: #747474;
  }
  .OrderCard__InnerLeftContainer {
    text-align: end;
    gap: 3rem;
  }
  .OrderCardDesc__Coin {
    margin-top: 3rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
  .OrderCard__TopBottomBorder {
    color: rgb(170, 166, 166);
    height: 5px;
  }
  .OrderCard__More__Btn {
    color: #d71513;
    text-decoration: underline;
    font-family: "Poppins";
    font-size: 14px;

    text-align: end;
  }
  .OrderCard__BottomCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .OrderCard__BottomLeft {
      color: black;
      font-weight: 500;
      text-decoration: underline;
    }
    .OrderCard__BottomRight {
      color: #d71513;
      font-weight: 500;
    }
  }
  .hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList {
    position: relative;
    width: 155.92px;
    height: 155.92px;
    object-fit: cover;
  }
  .hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList {
    background-color: var(--color-gainsboro);
    border: 1px solid var(--border-ededed);
    box-sizing: border-box;
    width: 155px;
    height: 155px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .OrderCard__Banner {
    width: 100px;
    height: 100px;
  }
  .OrderCard__Header {
    font-size: 12px !important;
  }
  .Ordercard__Title {
    font-size: 12px !important;
    margin-bottom: 0.2rem;
    font-weight: 400 !important;
  }
  .OrderCard__PriceTag {
    font-size: 15px !important;
    font-weight: 500;
    margin-bottom: 0.7rem;
  }
  .OrderCard__Details {
    flex-direction: column;
    gap: 0rem !important;
    align-items: start !important;
  }
  .OrderCard__Desc {
    font-size: 13px !important;
    margin-bottom: 0.2rem;
  }
  .Product__ColorTitle {
    font-size: 13px !important;
  }
  .OrderCard__BottomLeft {
    font-weight: 400 !important;
  }
  .OrderCard__BottomRight {
    font-weight: 400 !important;
  }
  .OrderCard__TopRightContainer {
    gap: 0.5rem !important;
  }
}

@media screen and (max-width: 450px) {
  .hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList {
    width: 100px !important;
    height: 100px !important;
  }
  .hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList {
    width: 100px !important;
    height: 100px !important;
  }
  .OrderCard__Banner {
    width: 50% !important;
  }
}
