.mask-group-iconBillContainer,
.p-coin-iconBillContainer {
  position: relative;
  width: 363px;
  height: 86px;
}
.p-coin-iconBillContainer {
  width: 49px;
  height: 49px;
  overflow: hidden;
  flex-shrink: 0;
}
.divBillContainer {
  position: relative;
  font-weight: 600;
}
.available-p-coinsBillContainer {
  position: relative;
  font-size: 14px;
  color: var(--color-gray-100);
}
.p-coin-parentBillContainer,
.parentBillContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.p-coin-parentBillContainer {
  width: 190.44px;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xs);
  margin-left: -190px;
}
.mask-group-parentBillContainer {
  width: 355px;
  height: 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid var(--primary-d71513);
  border-radius: 8px;
}
.frame-wrapperBillContainer,
.frame-wrapper1BillContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.frame-wrapperBillContainer {
  background-color: #F6FBFF;
  padding: var(--padding-lg);
  justify-content: flex-start;
  font-size: var(--px-semibold-size);
  color: var(--primary-d71513);
}
.frame-iconBillContainer {
  position: relative;
  width: 21px;
  height: 21px;
}
.apply-couponBillContainer {
  position: relative;
  font-weight: 500;
  font-size: 14px;
}
.frame-parent5BillContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.chevron-down-icon6BillContainer {
  position: relative;
  border-radius: var(--br-981xl);
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent4BillContainer,
.frame-wrapper2BillContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frame-wrapper2BillContainer {
  background-color: #F6FBFF;
  flex-direction: column;
  padding: var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
}
.bill-detailsBillContainer {
  align-self: stretch;
  font-weight: 500;
}
.bill-detailsBillContainer,
.sku-hrw-not-0001601BillContainer {
  position: relative;
}
.sku-hrw-not-00016011BillContainer {
  position: relative;
  color: var(--base-black);
  text-align: right;
}
.sku-hrw-not-0001601-parentBillContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.sku-hrw-not-00016013BillContainer,
.sku-hrw-not-00016017BillContainer {
  position: relative;
  color: var(--color-seagreen);
  text-align: right;
}
.sku-hrw-not-00016017BillContainer {
  color: var(--primary-d71513);
}
.line-divBillContainer {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--border-ededed);
  box-sizing: border-box;
  height: 1px;
}
.sku-hrw-not-000160111BillContainer {
  position: relative;
  font-weight: 500;
  text-align: right;
}
.sku-hrw-not-0001601-parent3BillContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--base-black);
}
.bill-details-parentBillContainer,
.frame-parent6BillContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent6BillContainer {
  align-items: flex-end;
  gap: var(--gap-3xs);
  font-size: var(--m3-label-large-size);
  color: var(--grey-767676);
}
.bill-details-parentBillContainer {
  align-items: flex-start;
  gap: var(--gap-sm);
  font-size: var(--px-medium-size);
}
.buttonBillContainer,
.state-layer1BillContainer {
  align-items: center;
  justify-content: center;
}
.state-layer1BillContainer {
  display: flex;
  flex-direction: row;
}
.buttonBillContainer {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-mistyrose);
  overflow: hidden;
}
.buttonBillContainer,
.button-wrapperBillContainer,
.frame-parent3BillContainer {
  display: flex;
  flex-direction: column;
}
.button-wrapperBillContainer {
  align-self: stretch;
  padding: var(--padding-3xs) 16px;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  color: white;
  background: #F9DFDF;
  border-radius: 8px;
  cursor: not-allowed !important;
}
.frame-parent3BillContainer {
  background-color: var(--m3-sys-light-on-primary);
  border-left: 1px solid var(--border-ededed);
  box-sizing: border-box;
  width: 439px;
  padding: var(--padding-5xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--m3-label-large-size);
  color: var(--base-black);
  font-family: var(--text-md-semibold);
}

