.you-can-earn {
    position: relative;
    font-weight: 500;
    display: inline-block;
    width: 90%;
    flex-shrink: 0;
  }
  .p-coin-icon2EarnCoinsForm {
    position: relative;
    width: 18.83px;
    height: 19px;
    overflow: hidden;
    flex-shrink: 0;
    // margin-left: 8px;
  }
  .you-can-earn-50-p-coins-for-co-parent {
    width: 361px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .frame-wrapper2 {
    align-self: stretch;
    background-color: var(--primary-d71513);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-mini);
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--m3-sys-light-on-primary);
    font-family: var(--text-md-semibold);
  }
  