.supporting-textFooterFront {
    position: relative;
    line-height: 24px;
    display: inline-block;
    width: 726px;
  }
  .logo-and-supporting-textFooterFront {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .icon6FooterFront {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text21FooterFront {
    position: relative;
    line-height: 24px;
    font-weight: 600;
  }
  .buttons1FooterFront {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .footer-linkFooterFront {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .footer-linksFooterFront {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .logo-and-linksFooterFront {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .headingFooterFront {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  .app-store-iconFooterFront {
    position: absolute;
    height: 39.3%;
    top: 44.43%;
    bottom: 16.26%;
    left: 46.45px;
    max-height: 100%;
    width: 75.08px;
  }
  .download-on-theFooterFront {
    position: absolute;
    height: 15.93%;
    top: 21.04%;
    bottom: 63.03%;
    left: 47.65px;
    max-height: 100%;
    width: 69.15px;
  }
  .apple-logo-iconFooterFront {
    position: absolute;
    height: 54.44%;
    top: 21.8%;
    bottom: 23.76%;
    left: 13.97px;
    max-height: 100%;
    width: 17.72px;
  }
  .mobile-app-store-badgeFooterFront {
    cursor: pointer;
    border: 1px solid var(--base-white);
    padding: 0;
    background-color: transparent;
    position: relative;
    border-radius: var(--br-6xs);
    box-sizing: border-box;
    width: 135px;
    height: 40px;
  }
  .google-play-iconFooterFront {
    position: absolute;
    height: 42.58%;
    top: 42.62%;
    bottom: 14.81%;
    left: 41.04px;
    max-height: 100%;
    width: 84.78px;
  }
  .get-it-onFooterFront {
    position: absolute;
    height: 16.17%;
    top: 16.91%;
    bottom: 66.91%;
    left: 41.25px;
    max-height: 100%;
    width: 39.05px;
  }
  .google-play-logoFooterFront {
    position: absolute;
    height: 64.3%;
    top: 17.85%;
    bottom: 17.85%;
    left: 9.97px;
    max-height: 100%;
    width: 23.09px;
  }
  .mobile-app-store-badge1FooterFront {
    cursor: pointer;
    border: 1px solid var(--base-white);
    padding: 0;
    background-color: transparent;
    position: relative;
    border-radius: var(--br-8xs);
    box-sizing: border-box;
    width: 135px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .actions1FooterFront {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .app-store-actionsFooterFront {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    color: var(--base-white);
  }
  .content10FooterFront {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .container2FooterFront {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0px var(--padding-13xl);
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-200);
    font-family: var(--text-md-semibold);
  }
  
  @media screen and (max-width: 420px) {
    .logo-and-linksFooterFront {
      flex: unset;
      align-self: stretch;
    }
  
    .content10FooterFront {
      flex-direction: column;
    }
  }
  