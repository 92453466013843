.DailyBonus__Hover__Claim__Card{
    width: 100%;
    background-color: #D71513;
    padding: 1rem;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    font-family: "Poppins";
    font-size: 14px;
    margin-bottom: 1rem;
    
.DailyBonus__Card__Btn {
        background-color: #ffffff;
        color: #D71513;
        font-family: "Poppins";
        font-size: 13px;
        border-radius: 4px;
        padding: 0.3rem 1rem;
        cursor: pointer;
        transition-duration:500ms;
    }
    
   
       

    
}

.DailyBonus__Card{
    width: 100%;
        background-color: #ffffff;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        font-family: "Poppins";
        cursor: pointer;
    margin-bottom: 1rem;            
    
        .DailyBonus__Card__Text{
            color: gray;
        }
}
.DailyBonus__Card__Claimed{
    width: 100%;
        background-color: #279A2C;
        color: #ffffff;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        font-family: "Poppins";
        cursor: pointer;
        margin-bottom: 1rem;
        
}
.DailyBonus__Hover__Claimed__Card{
        width: 100%;
        background-color: #F5F5F5;
        border: 1px solid #D71513;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        font-family: "Poppins";
        cursor: pointer;
        font-size: 14px;
       
        margin-bottom:1rem ;
        .DailyBonus__Card__BtnClaimed {
                background-color: #CBC8C8;
                color: #ffffff;
                font-family: "Poppins";
                font-size: 13px;
                border-radius: 4px;
                padding: 0.3rem 1rem;
                cursor: pointer;
                z-index: 1;
            }
}
.DailyLoginBonus__Left__Container {
    display: flex;
    align-items: center;
    gap: 0.2rem
}
