.logo-1-icon1 {
    position: relative;
    width: 156px;
    height: 36px;
    object-fit: cover;
    
  }
  .label1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  .search-md-icon1 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text17 {
    position: relative;
    line-height: 24px;
  }
  .content11 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .input1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--grey-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-500);
  }
  .input-with-label1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .hint-text1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--grey-600);
    display: none;
  }
  .input-dropdown1 {
    align-self: stretch;
    width: 574px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .content10 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }
  .nav-item-button3 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .heart-icon1 {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .nav-item-button4 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    width: 40px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .notifications-icon1 {
    position: relative;
    width: 21.5px;
    height: 21.5px;
  }
  .actions1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
  }
  .avatar-icon1 {
    position: relative;
    border-radius: var(--br-181xl);
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .dropdown1 {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content12 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .container2 {
    width: 1280px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base) 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
  }
  .divider1 {
    align-self: stretch;
    position: relative;
    background-color: var(--grey-200);
    height: 1px;
  }
  .dot-icon7 {
    position: relative;
    width: 10px;
    height: 10px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text18 {
    position: relative;
    font-weight: 500;
  }
  .content13 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .text19 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  .badge7 {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--grey-700);
  }
  .chevron-down-icon7 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .nav-item-base6 {
    border-radius: var(--br-7xs);
    background-color: var(--grey-50);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .bar-chart-01-icon6 {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .badge8 {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
  }
  .nav-item-base7 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .nav-item-base-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .p-coin-icon1 {
    position: relative;
    width: 22px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .nav-item-base11 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    border: 1px solid var(--gray-500);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .navigation1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
    color: var(--grey-700);
  }
  .container3 {
    width: 1280px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base) 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-101xl);
    font-size: var(--text-md-semibold-size);
    color: var(--grey-900);
  }
  .header-navigation1 {
    align-self: stretch;
    background-color: var(--m3-sys-light-on-primary);
    border-bottom: 1px solid var(--grey-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-group {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xl);
  }
  .cart-inner {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-xs) var(--padding-61xl) var(--padding-13xl);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .cart {
    position: relative;
    background-color: var(--m3-sys-light-on-primary);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--m3-label-large-size);
    color: var(--grey-700);
    font-family: var(--text-md-semibold);
  }

  .cartCartItems {
    position: relative;
    background-color: var(--m3-sys-light-on-primary);
    width: 100%;
    align-items: flex-start;
    font-size: var(--m3-label-large-size);
    color: var(--grey-700);
  }


  .cart-innerCartItems {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: var(--padding-xs) 80px var(--padding-13xl);
    justify-content: flex-start;
    text-align: center;
    font-size: var(--px-semibold-size);
    color: var(--base-black);
    font-family: var(--text-md-semibold);
  }


  .frame-parentCartItems {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    // gap: var(--gap-5xl);
  }
  
  @media screen and (max-width: 960px) {
    .frame-group {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .frame-parentCartMain {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

  }

@media screen and (max-width: 1100px) {

  .frame-parentCartItems {
    flex-direction: column;
    align-items: center;
  }
  
}