.featured-icon {
    position: relative;
    border-radius: var(--br-9xl);
    width: 66px;
    height: 66px;
  }
  .text11 {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  .we-sent-a {
    margin: 0;
  }
  .p {
    margin: 0;
    font-weight: 500;
  }
  .supporting-text {
    align-self: stretch;
    position: relative;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-500);
  }
  .text-and-supporting-text {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .header1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .mega-input-field-base {
    border: 1px solid var(--grey-300);
    font-weight: 500;
    font-family: var(--text-sm-semibold);
    font-size: var(--display-lg-medium-size);
    background-color: transparent;
    border-radius: var(--br-5xs);
    width: 80px;
    display: flex;
    text-align: center;
    align-items: flex-end;
    /* padding-left: 10px; */
    color: var(--primary-d71513);
    outline: none;
  
  }
  
  
  .hintpass {
    /* align-self: stretch; */
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    /* color: var(--primary-d71513); */
    text-align: left;
    /* display: none; */
    color: var(--primary-d71513);
    margin-bottom: 0;
  }
  
  
  
  .mega-input-field-base:hover {
    outline: 2px solid var(--primary-d71513);
  
  }
  
  .mega-input-field-base2 {
    border: 4px solid #ffe2e1;
    font-weight: 500;
    font-family: var(--text-sm-semibold);
    font-size: var(--display-lg-medium-size);
    background-color: transparent;
    border-radius: var(--br-5xs);
    box-sizing: border-box;
    width: 88px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .text12 {
    flex: 1;
    position: relative;
    line-height: 60px;
    font-weight: 500;
  }
  
  
  .backarrow {
    padding-inline: 5px;
  }
  
  .content3Otp {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background-color: #ffffff;
    padding: 2rem;
  }
  
  .input7 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--grey-300);
    box-sizing: border-box;
    height: 80px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs);
    align-items: center;
    justify-content: flex-start;
  }
  .mega-input-field-base3 {
    border-radius: var(--br-5xs);
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .input6 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.6rem;
  }
  .verification-code-input-field {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .icon11 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text13 {
    position: relative;
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    font-weight: 600;
    font-family: var(--text-sm-semibold);
    color: var(--base-white);
    text-align: left;
  }
  .buttons3 {
    cursor: pointer;
    border: none;
    padding: var(--padding-3xs) var(--padding-lg);
    background-color: var(--primary-d71513);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .disabling{
border: none;
  padding: var(--padding-3xs) var(--padding-lg);
  align-self: stretch;
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  background-color: #767676;
  pointer-events: none;
  color:white
  }
  .content4 {
    align-self: stretch;
    border-radius: var(--br-xs);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    font-size: var(--display-lg-medium-size);
    color: var(--grey-300);
  }
  .didnt-receive-the {
    position: relative;
    line-height: 20px;
  }
  .text14 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    
  }
  .buttons4Verification {
    border-radius: var(--br-5xs);
    background-color: transparent;
    border: none;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    color: var(--primary-d71513);
    font-family: Poppins, sans-serif;
  }
  .row1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--grey-500);
  }
  .icon15 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text15Verification {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: var(--grey-600);
    text-align: left;
  }
  .buttons5 {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .phoneNumberVerificationContent {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    background-color: #ffffff;
    padding: 2rem;
    .content4{
      flex-direction: column;
    }
    .text15{
      line-height: normal;
      font-size: 15px;
      font-family: "Poppins";
      color:#767676
    }
  }
  .phone-number-verification {
    position: relative;
    background-image: url("../../../../images/popUpBackground.svg");
    width: 100%;
    height: 960px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-77xl) var(--padding-13xl) var(--padding-29xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: var(--display-sm-semibold-size);
    color: var(--grey-900);
    font-family: var(--text-sm-semibold);
  }
  
  @media screen and (max-width: 420px) {
    .row1 {
      flex-direction: column;
      gap: var(--gap-9xs);
      align-items: center;
      justify-content: center;
    }
  }
  