.frame-itemOrderingSteps {
    position: relative;
    border-radius: 50%;
    background-color: var(--grey-767676);
    width: 10px;
    height: 10px;
  }
  .ellipse-wrapperOrderingSteps {
    border-radius: var(--br-981xl);
    border: 1px solid var(--color-seagreen);
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .bagOrderingSteps {
    position: relative;
  }
  .frame-divOrderingSteps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    color: var(--color-seagreen);
  }
  .frame-innerOrderingSteps {
    position: relative;
    width: 61px;
    height: 1px;
  }
  .vector-parentOrderingSteps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-parent1OrderingSteps {
    align-items: center;
    gap: var(--gap-8xs);
  }
  .frame-containerOrderingSteps,
  .frame-parent1OrderingSteps,
  .frame-parent2OrderingSteps {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .frame-parent2OrderingSteps {
    align-items: center;
    gap: var(--gap-10xs);
    text-align: right;
  }
  .frame-containerOrderingSteps {
    align-items: flex-start;
    gap: var(--gap-8xs);
  }
  .cart-childOrderingSteps {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xl) var(--padding-3xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-767676);
    font-family: var(--text-md-semibold);
  }
  @media screen and (max-width: 960px) {
    .cart-childOrderingSteps {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  