.modal-dialog{
    // width:60% !important;
    // max-width: 50% !important;
    .Modal__Body__Cont{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .Cross__Icon{
        cursor: pointer;
        width: 4%;
        height: 2%;
    }
    .Modal__midContainer{
        border:1px solid #EDEDED;
        margin:0.5rem 0;
        padding:0.5rem;
    }
    .Modal__lastContainer{
        border: 1px solid #EDEDED;
            margin: 0.5rem 0;
            padding: 0.5rem;
    }
    .Modal__Header2{
        margin-bottom:0.1rem !important;
        font-size: 14px;
    }
    .Modal__Title{
        font-family: "Poppins";
        font-size: 20px;
        text-transform:capitalize ;
        margin-bottom: 1rem;

    }
    .Modal__TextPlace{
        width:100%;
        height: 30%;
        border-color: #EDEDED;
        border-radius: 5px;
        padding: 1rem;
        &:focus{
            outline: none;
        }
    }
    .Modal__Btn{
        display: flex;
        margin: 0.5rem 0;
        margin-left:auto !important;
        border-radius:5px ;
        padding:0.3rem 4rem;
        background-color: #D71513;
        color:#FFFFFF;
        cursor: pointer;
    }
    .Success__Modal__Cont{
        text-align: center;
    }
}
@media screen and (max-width: 970px) {
    .modal-dialog {

    }
}