.icon2 {
    position: relative;
    width: 10px;
    height: 7px;
  }
  .icon-wrapper {
    border-radius: var(--br-981xl);
    background-color: var(--color-seagreen);
    width: 17px;
    height: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bag {
    position: relative;
  }
  .frame-parent2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .frame-item {
    position: relative;
    width: 61px;
    height: 1px;
  }
  .frame-inner {
    position: relative;
    border-radius: 50%;
    background-color: var(--grey-767676);
    width: 10px;
    height: 10px;
  }
  .ellipse-wrapper {
    border-radius: var(--br-981xl);
    border: 1px solid var(--color-seagreen);
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .vector-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-parent3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    color: var(--color-teal);
  }
  .frame-parent4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-10xs);
    text-align: right;
    color: var(--grey-767676);
  }
  .frame-parent1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }

  .address-child {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xl) var(--padding-3xs);
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--color-seagreen);
    font-family: var(--text-md-semibold);
    width: fit-content;
    margin-inline: auto;
  }
  