.app-store-icon {
    position: absolute;
    height: 39.3%;
    top: 44.44%;
    bottom: 16.26%;
    left: 46.45px;
    max-height: 100%;
    width: 75.08px;
  }
  .download-on-the {
    position: absolute;
    height: 15.93%;
    top: 21.04%;
    bottom: 63.03%;
    left: 47.65px;
    max-height: 100%;
    width: 69.15px;
  }
  .apple-logo-icon {
    position: absolute;
    height: 54.44%;
    top: 21.8%;
    bottom: 23.76%;
    left: 13.97px;
    max-height: 100%;
    width: 17.72px;
  }
  .mobile-app-store-badge {
    cursor: pointer;
    border: 1px solid var(--base-white);
    padding: 0;
    background-color: transparent;
    position: relative;
    border-radius: var(--br-6xs);
    box-sizing: border-box;
    width: 135px;
    height: 40px;
  }
  