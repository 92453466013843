
.OrderPage{
    width:100%;
}
   .OrderPage__InnerContainer {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    h3{
        font-family: sans-serif;
        font-weight: 540;
        font-size: 20px;
        color:#282C3F;
    }
    }
    .OrderPage__SearchContainer{
        position:relative;
        width:60%;
        .OrderPage__SearchIcon{
            position: absolute;
            top: 12px;
                left: 8px;
        }
        .OrderPage__SearchInput{
            padding:0.5rem 2rem;
                    border: 1px solid #EDEDED;
                    width: 100%;
                    border-radius: 5px;
                    &:focus{
                        outline: none;
                    }
                    &::placeholder{
                        font-size: 15px;
                        font-family: "Poppins";
                        line-height: 10px;
                    }
        }
        

    }
        .paginationContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
    
            .paginateControls {
                display: flex;
                align-items: center;
                gap: 1.5rem;
    
                .paginateBtn {
                    border: 1px solid black;
                    border-radius: 5px;
                    padding: 0.2rem 1rem;
                }
    
                .paginateText {
                    color: #D71513
                }
            }
        }
 @media screen and (max-width: 600px) {


     .OrderPage__InnerContainer {
         flex-direction: column !important;
         justify-content: flex-start;
         }
                 .paginationContainer {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    
                 }
                 .paginateBtn{
                    margin-top: 0.5rem;
                 }
    

 }