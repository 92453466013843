
    .modal-dialog {
       
        .Modal__Message__Text{
            font-family: "Poppins";
            font-weight: 500;
            margin-top: 1rem;
        } 
        .Success__Modal__Cross__Icon{
            margin-left: auto;
            width: 4%;
            height: 2%;
            cursor: pointer;
            margin-top: 1rem;
            margin-right: 1rem;
        }
    }
 
