.delete-account{
 &-page-wraper{
    background: var(--Gray-50, #F9FAFB);
    height: 100vh;
    width: 100%;
    }
 
    &-wraper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 1440px;
        padding: 0px 80px 77px 80px;
        flex-shrink: 0;
        margin: 0 auto;
        form{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 26px;
            align-items: self-end;
            .form-control:focus{
                box-shadow: none;
                border-color: #dee2e6;
            }
            button{
                border: none;
                display: flex;
                width: 194px;
                padding: 8px 18px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background: var(--Primary---D71513, #D71513);
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                &:hover{
                    background: var(--Primary---D71513, #D71513);
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }
                &:focus{
                    background: var(--Primary---D71513, #D71513);

                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }
                &:active{
                    background-color: var(--Primary---D71513, #D71513) !important;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
                }
            }
        }
    }
    &-header-block{
        text-align: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        display: flex;
        align-items: center;
        img{
            width: 330px;
            height: 76px;
        }
        h3{
            color: var(--Gray-700, #404040);
            text-align: center;
            font-family: 'Poppins';
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 37px;
        }
    }
    &-page-title{
        color: var(--Gray-700, #404040);
        font-family: 'Poppins';
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    &-content-wrap{
        display: flex;
        gap: 24px;
    }
    &-content-box{
        display: flex;
        height: 461px;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 21px;
        flex: 1 0 0;
        border-radius: 12px;
        background: #FFF;
        h3{
            color: var(--Gray-700, #404040);
            font-family: 'Poppins';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 155%; /* 37.2px */
        }
        ul{
            padding-left: 0;
            li{
                color: var(--Gray-600, #525252);
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                position: relative;
                list-style: none;
                padding-left: 20px;
                &::before{
                    content: '';
                    height: 5px;
                    width: 5px;
                    position: absolute;
                    border-radius: 100px;
                    background-color: #525252;
                    left: 0;
                    top: 10px;
                }
            }
        }
    }
    &-content-primary-color{
        color: var(--Primary---D71513, #D71513);
    }
    &-title{
        color: var(--Gray-700, #404040);
        font-family: 'Poppins';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 155%; /* 37.2px */
    }
    &-verification-code-modal-body{
        .input6{
          gap: 30px;
          justify-content: center;  
          margin-bottom: 13px;
        }
        .mega-input-field-base{
            border-color: #d71513;
        }
        .resend-text{
            text-align: center;
            color: var(--Gray-500, #737373);
            text-align: center;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            margin-bottom: 30px;
            span{
                color: #d71513;
                cursor: pointer;
            }
        }
    }
    &-confermation-modal-body{
        h2{
            color: var(--Gray-800, #262626);
            font-family: 'Poppins';
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        p{
            color: var(--Gray-800, #262626);
            font-family: 'Poppins';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 30px;
        }
    }
    &-success-modal-body{
        text-align: center;
        h2{
            color: var(--Success-500, #12B76A);
            text-align: center;
            font-family: 'Poppins';
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        p{
            color: var(--Gray-800, #262626);
            text-align: center;
            font-family: 'Poppins';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    &-modal{
        max-width: 684px;
        padding: 40px;
        border-radius: 8px;
        background-color: #FFFFFF;
        margin: 0 auto;
        position: relative;
        box-shadow: -1px 6px 13px -3px rgb(211 211 211 / 42%);
        -webkit-box-shadow: -1px 6px 13px -3px rgb(211 211 211 / 42%);
        -moz-box-shadow: -1px 6px 13px -3px rgb(211 211 211 / 42%);
        .modal-close-btn{
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
        }
    }
    &-verification-code-title-block{
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 7px;
        flex-direction: column;
        margin-bottom: 30px;
        h4{
            color: var(--Gray-800, #262626);
            text-align: center;
            margin-bottom: 0;
            /* Text lg/SemiBold */
            font-family: 'Poppins';
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
        }
        p{
            color: var(--Grey-600, #475467);
            text-align: center;
            margin-bottom: 0;
            /* Text sm/Regular */
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }
}

.delete-account-btn-group{
    display: flex;
    gap: 12px;
    .cancel-btn{
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
         border-radius: 8px;
        border: 1px solid var(--Grey-300, #D0D5DD);
        background: var(--Base-White, #FFF);
        color: var(--Grey-600, #475467);

        /* Text md/SemiBold */
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .verify-btn{
        display: flex;
        padding: 8px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
         border-radius: 8px;
        border: 1px solid var(--Primary---D71513, #D71513);
        background: var(--Primary---D71513, #D71513);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Base-White, #FFF);

        /* Text md/SemiBold */
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        &:active{
            background: var(--Primary---D71513, #D71513);
        }
    }
    
}
.Input__Field{
    padding: 0.4rem 0.4rem 0.4rem 2rem !important;
}
.Country__Code{
    font-family: "Poppins";
        font-size: 15px;
        color: #475467;
        position: absolute;
        top: 8px;
        left: 5px;
}

@media screen and (max-width: 1080px) {
    .delete-account-content-box h3{
       font-size: 18px;
    }
    .delete-account-header-block h3{
        font-size: 30px;
    }
    .delete-account-page-title{
        font-size: 25px;
    }
    .delete-account-wraper{
        padding: 0px 30px;
    }
}

@media screen and (max-width: 980px) {
    .delete-account-content-wrap{
        flex-direction: column;
    }
}

@media screen and (max-width: 680px) {
    .delete-account-verification-code-modal-body{
        .mega-input-field-base{
            width: 52px;
            height: 52px;
            font-size: 30px;
        }
    }
    .delete-account-modal{
        padding: 15px;
    }
} 
@media screen and (max-width: 480px) {

    .delete-account{
        &-confermation-modal-body{
            .delete-account-btn-group{
                flex-direction: column;
            }
        }
    }
}