.ProfilePCoin__Container{
    width: 100%;
    background-color: #ffffff;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
    .ProfilePCoin__Container__Main__Head {
            font-weight: 400;
        }
    .ProfilePCoin__Inner__Top__Container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        
        .ProfilePCoin__Inner__Inner__Container{
            display: flex;
            align-items: center;
        }
        .ProfilePCoin__Btn{
            border: 1px solid #E5A413;
            color: #E5A413;
            background-color: #FFFAF0;
            font-family: "Poppins";
            border-radius: 15px;
            padding: 0.1rem 0.7rem;
            font-size: 14px;
        }
        .ProfilePCoin__OtherBtn{
            border: 1px solid #1D9C5F;
                color: #1D9C5F;
                background-color: #ECFFF6;
                font-family: "Poppins";
                border-radius: 15px;
                padding: 0.1rem 0.7rem;
                font-size: 14px;
        }
        .ProfilePCoin__Text{
            color:#D71513;
            font-family: "Poppins";
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 500;
        }
        .ProfilePCoin__OtherText{
            color: #1D9C5F;
                font-family: "Poppins";
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 500;
        }
    }
    .ProfilePCoin__Inner__Bottom__Container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #EDEDED;
        margin-top:0.5rem;
        padding-top:0.7rem;
        .ProfilePCoin__Inner__Bottom__Container__Text{
            color:gray;
            font-family: "Poppins";
            font-size: 12px;
            margin: 0;
        }
    }
}
