.catagories__container{
  .catagories__container_btn{
    background: transparent;
    display: flex;
    gap: 10px;
    border-radius: 6px;
    background: var(--grey-50, #F9FAFB);
    color: var(--grey-900, #101828);
    /* 16px/Medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    padding: 15px 12px;
    // pointer-events: none;
    &:hover,
    &:first-child:active{
      background: var(--grey-50, #F9FAFB);
      color: var(--Primary---D71513, #D71513);
    }
  }
}

.catagories__container__inner{
  position: absolute;
  left: 16px !important;
    top: 80px !important;
    width:24%;
        z-index: 3 !important;
    transition-duration: 500ms;
    animation: growDown 300ms ease-in-out forwards;
    
  .popover{
    border-radius: 0;
    border: none;
    min-width: 330px;
    left: -68px !important;
    top: -8px !important;
    transform: translate(80px, 149px) !important;
    .popover-arrow{
      display: none;
    }
  }
  .popover-body{
    padding: 0;
    border: none;
    background: transparent;
  }
  .accordion-header-main{
    width: 100%;
    padding: 0;
    .accordion-header-inner{
      display: flex;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      
      
      .accordion-title-block{
        display: flex;
        align-items: center;
        gap: 15px;
      }
      .cat-list-icon-block {
        img{
          width: 24px;
          height: 25px;
          max-width: 100%;
        }
      }
      h3{
        margin: 0;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #1C1C1C;
      }
      svg{
        transition: all 0.3s ease-in;
        color: #737373;
      }
    }
    .accordion-header-inner:hover{
      background-color: #F8D2D1 !important;
      border:1px solid #D71513;
          svg{
            color: #D71513;
          }
        
    }

    &.accordion-header-main-active{
      .accordion-header-inner{
        svg{
          transform: rotate(90deg);
          color: #D71513;
        }
      }
     
    }
  }
  .subCatagory-accordion-header-main{
    width: 100%;
    padding: 0;
    .subCatagory-accordion-header-inner{
      display: flex;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      .subCatagory-accordion-title-block{
        display: flex;
        align-items: center;
        gap: 15px;
      }
      .cat-list-icon {
        width: 30px;
      }
      h3{
        margin: 0;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #1C1C1C;
      }
      svg{
        transition: all 0.3s ease-in;
        color: #737373;
      }
    }
    &.subCatagory-accordion-header-main[aria-expanded=true]{
      svg{
        transform: rotate(90deg);
        color: #D71513;
      }
    }
  }
  .accordion-body{
    padding: 15px;
    background-color: #ffffff;
    z-index: 4;

    p{
      margin: 5px;
    }
  }
}

@media screen and (max-width: 1064px){
  .catagories__container__inner{
    width:300px;
        z-index: 1;
    .popover{
      transform: translate(15px, 149px) !important;
    }
}}