.canon-e3370-rd-printer-photo2-icon7 {
    position: relative;
    border-radius: var(--br-8xs) var(--br-8xs) 0px 0px;
    width: 220px;
    height: 210px;
    object-fit: cover;
  }
  .canon-pixma-e3370r {
    align-self: stretch;
    position: relative;
    font-weight: 500;
  }
  .rm39900 {
    position: relative;
  }
  .rm34500 {
    position: relative;
    font-weight: 500;
    color: var(--base-black);
  }
  .off1 {
    position: relative;
    color: var(--color-orangered-100);
  }
  .rm39900-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .p-coin-icon3 {
    position: relative;
    width: 16px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .you-can-earn-group {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-10xs);
  }
  .frame-parent7 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--px-medium-size);
    color: var(--grey-767676);
  }
  .canon-pixma-e3370r-aio-wifi-in-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-wrapper6 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xs) var(--padding-mini) var(--padding-mini);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .canon-e3370-rd-printer-photo2-group {
    border-radius: var(--br-8xs);
    background-color: var(--base-white);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    width: 220.8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-sm-medium-size);
    color: var(--base-black);
    font-family: var(--text-sm-medium);
  }
  