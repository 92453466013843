

.PasswordChange__Main__Container{
 width:40%;
 margin: 0 auto;
    .modal {
        border-width: 0;
        border-radius: 0;
    }
    
    .modal-dialog {
        width: 100vw !important;
        height: 100vh !important;
        margin: 0 !important;
    }
    
    .modal-content {
        width: 100vw !important;
        height: 100vh !important;
        margin: 0 !important;
    
    }
}