/* Updated--Styles */
.productDetails {
  &__page {
    font-family: "Poppins";
  }
  &__showcase__block {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 40px;
    margin-top: 30px;
  }
  &__media__showcase__block {
    flex: 1 1;
    max-width: 550px;
    min-width: 50%;
    margin: 0 auto;
    text-align: center;
    .react-tabs {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    .react-tabs__tab-panel {
      border-radius: 5px;
      border: 1px solid #ebebeb;
      padding: 30px;
      // img{
      //     min-width: 550px;
      //     max-width: 550px;
      // }
    }
    .react-tabs__tab-list {
      border: none;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .react-tabs__tab {
      position: static;
      width: 80px;
      height: 80px;
      border-radius: 5px;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    .react-tabs__tab--selected {
      border: 2px solid var(--Primary---D71513, #d71513);
      &::after {
        content: none;
        display: none;
      }
    }
    img {
      max-width: 100%;
    }
  }
  &__content__showcase__block {
    flex: 1 1;
  }
  &__divider {
    border-bottom: 1px solid var(--Border---EDEDED, #ededed);
  }
  &__content {
    &__title {
      color: var(--base-black, #1c1c1c);
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &__ratting__block {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0px;
      span {
        color: #168952;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &__pricing__block {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0px;
    }
    &__pricing {
      &--off--pricing {
        color: var(--Grey---767676, #888686);
        text-decoration: line-through;
        font-size: 17px;
        margin-top: 9px !important ;
      }
      &--on--pricing {
        color: var(--base-black, #1c1c1c);
        display: flex;
        font-weight: 500;
      }
      &--discount--pricing {
        color: #eb5806;
        font-size: 17px;
        margin-top: 9px !important ;
      }
    }
    &__coins__block {
      display: flex;
      gap: 8px;
      align-items: center;
      flex-wrap: wrap;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 15px;
    }
    &__coins {
      &__block {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &--off--coins {
        color: var(--Grey---767676, #888686);
      }
      &--on--coins {
        display: flex;
        gap: 8px;
        align-items: center;
        color: var(--base-black, #1c1c1c);
      }
    }
    &__color__block {
      margin-bottom: 0px;
      h5 {
        color: var(--base-black, #1c1c1c);
        /* 16px/Medium */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    &__variation__block {
      margin-bottom: 10px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
    &__variation {
      &--attributes--block {
        display: flex;
        width: 124px;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid var(--Border---EDEDED, #ededed);
        div {
          p {
            margin-bottom: 0;
            text-transform: capitalize;
          }
        }
      }
    }
    &--sku {
      color: var(--base-black, #1c1c1c);
      /* 16px/Regular */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__btn__block {
      display: flex;
      padding-top: 12px;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      svg {
        font-size: 22px;
      }
    }
    &__btn--add-to-cart {
      display: flex;
      width: 279px;
      height: 54px;
      padding: 10px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--Primary---D71513, #d71513);
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &.add-to-cart-btn-disable {
        cursor: not-allowed !important;
      }
    }
    &__btn--add-to-wishlist {
      display: flex;
      width: 226px;
      height: 54px;
      padding: 10px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid var(--base-black, #1c1c1c);
      background: #fff;
      color: var(--base-black, #1c1c1c);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__details {
    &__review__block {
      position: relative;
      .earn__p-coins__btn {
        display: flex;
        height: 41px;
        padding: 10px 13px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--Grey---767676, #888686);
        background: #fff;
        color: var(--Grey---767676, #888686);
        /* 16px/Medium */
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: absolute;
        right: 0;
      }
      .react-tabs__tab-list {
        border-bottom: 1px solid #888686;
      }
      .react-tabs__tab {
        background: transparent;
        color: var(--Grey---767676, #888686);
        /* 24px/Semibold */
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: initial;
      }
      .react-tabs__tab--selected {
        border: none;
        color: var(--Primary---D71513, #d71513);
        border-bottom: 1px solid #d71513;
      }
    }
    &__block {
      h3 {
        color: var(--base-black, #1c1c1c);
        /* 20px/Medium */
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
      }
      p {
        color: var(--Grey---767676, #888686);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &__review__title__block {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    h3 {
      color: var(--base-black, #1c1c1c);
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
    }
    .react-stars {
      span {
        line-height: 24px !important;
      }
    }
  }
  &__review__stars__block {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
  &__review {
    &__content {
      &__item {
        &__title {
          &__block {
            display: flex;
            padding-bottom: 0px;
            align-items: center;
            gap: 11px;
            align-self: stretch;
            h4 {
              color: #4b4b4b;

              /* 16px/Regular */
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 0;
            }
          }
          &_ratting__block {
            display: flex;
            padding: 0px 3px;
            align-items: center;
            gap: 1px;
            border-radius: 2px;
            background: #168952;
            color: #fff;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 155.5%; /* 18.66px */
            svg {
              font-size: 6px;
            }
          }
        }
        &__block {
          margin: 0 24px;
          padding: 12px 0;
          border-bottom: 1px solid var(--Border---EDEDED, #ededed);
        }
        &__timestamp {
          color: var(--Grey---767676, #888686);
          /* 14px/Regular */
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: 32px;
        }
      }
    }
  }
}
.disableBtn {
  pointer-events: none !important;
}
.Product__Page__Quantity__Container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  border: 2px solid rgb(238, 237, 237);
  border-radius: 7px;
  padding: 0rem;
  width: 90px;
  text-align: center;
  padding: 0rem 0.5rem 0.2rem 0.5rem;
  margin-top: 0.5rem;
  .Product__Page__Quantity__Inner__Container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.related__product {
  &__block {
    margin-top: 65px;
  }
  &__title {
    color: var(--base-black, #1c1c1c);
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;
  }
  &__wrap__block {
    .slick-slide {
      padding: 15px;
    }
  }
}
.productDetails__content__showcase__block {
  margin-top: -9px;
}

// Css for Custom Carousel
.carousel-container {
  position: relative;
  height: 300px;
}
#carousel {
  height: 300px;
  overflow: hidden;
  scroll-behavior: smooth;
  #left {
    position: absolute;
    top: 0px;
    color: #c9c8c8;
    cursor: pointer;
  }

  #right {
    position: absolute;
    bottom: 0px;
    color: #c9c8c8;
    cursor: pointer;
  }
}

// Css
//   for
//   Custom
//   Carousel
// @media screen and (min-width: 1560px) and (max-width: 3060px) {
//   #carousel {
//     height: 400px;
//   }
// }
@media screen and (max-width: 1560px) {
  .productDetails__media__showcase__block {
    div {
      // width:100% !important;
    }
    // .react-tabs__tab-panel{
    //     img{
    //         min-width: 470px;
    //         max-width: 470px;
    //     }
    // }
  }
  #carousel {
    height: 300px;
  }
}

@media screen and (max-width: 1380px) {
  .productDetails__media__showcase__block {
    .react-tabs__tab-panel {
      img {
        min-width: 415px;
        max-width: 415px;
      }
    }
  }
  .productDetails__content__showcase__block {
    flex: 0.5 1;
  }
  #carousel {
    height: 300px;
  }
}

@media screen and (max-width: 1280px) {
  .productDetails__content {
    &__btn--add-to-cart {
      width: 191px;
      padding: 0;
    }
    &__btn--add-to-wishlist {
      width: 191px;
      padding: 0;
    }
  }
}

@media screen and (max-width: 1140px) {
  .productDetails__content {
    &__btn--add-to-cart {
      width: 158px;
      padding: 0;
    }
    &__btn--add-to-wishlist {
      width: 158px;
      padding: 0;
    }
  }
}

@media screen and (max-width: 980px) {
  .productDetails__media__showcase__block {
    .react-tabs__tab-panel {
      img {
        min-width: 315px;
        max-width: 315px;
      }
    }
  }
  .productDetails__content__showcase__block {
    flex: 0.8 1;
  }
}

@media screen and (max-width: 900px) {
  .productDetails {
    &__showcase__block {
      flex-direction: column;
      gap: 50px;
    }
    &__media__showcase__block {
      width: 100%;
      max-width: none;
      img {
        margin: 0 auto;
      }
    }
    &__content__showcase__block {
      width: 100%;
    }
  }
  .related__product__block {
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .productDetails {
    &__showcase__block {
      margin-bottom: 20px;
    }
    &__details__review__block {
      .earn__p-coins__btn {
        position: relative;
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 530px) {
  .productDetails__media__showcase__block {
    .react-tabs__tab-panel {
      img {
        min-width: 470px;
        max-width: 470px;
      }
    }
  }
  .productDetails {
    &__content {
      &__btn--add-to-cart {
        width: 100%;
      }
      &__btn--add-to-wishlist {
        width: 100%;
      }
      &__title {
        font-size: 24px;
      }
      &__pricing__block {
        font-size: 19px;
      }
    }
    &__review {
      &__title__block {
        flex-direction: column;
        gap: 0;
      }
    }
    &__details__review__block {
      .react-tabs__tab {
        font-size: 19px;
      }
    }
    &__review__content__item__block {
      margin: 0;
    }
  }
  .related__product__title {
    font-size: 26px;
  }
  .related__product__item {
  }
  .related__product__wrap__block {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

// .related__product__item {
//   display: flex !important;
//   align-items: center !important;
//   gap: 1rem !important;
// }
// .related__product__Item {
//   width: 30%;
// }
.related__product__wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 594px) {
  .related__product__wrap {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    overflow-y: scroll;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.related__product__wrap::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.related__product__wrap {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.productDetails__details__block p {
  line-height: 1.7 !important;
}
