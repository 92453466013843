.AddressBook__Main__Container{
    width:100%;
    .AddressBook__Main__Container__Header{
        font-family: "Poppins";

    }
}
.AddressBook__Empty__Container{
    width: 100%;
    text-align: center;
    .AddressBook__Empty__Container__Header{
        font-family: "Poppins";
        margin:1rem 0;
    }
        .AddressBook__Empty__Container__Para {
            font-family: "Poppins";
            margin: 1rem 0;
            color: gray
        }
    .AddressBook__Empty__Container__Btn{
        border: 2px solid #d4d1d1;
        border-radius: 5px;
        color:#D71513;
        font-family: "Poppins";
        font-weight: 500;
        text-align: left;
        padding: 1rem 5rem 1rem 1rem;
        
    }
}
@media screen and (max-width:400px){
    .AddressBook__Empty__Container__Banner{
        width: 100%;

    }
.AddressBook__Empty__Container__Btn {
    padding: 1rem;
    width:100%;
}


    
}
