.DeleteAcc__Modal__Para{
    font-family: "Poppins";
    font-weight: 200;
    color: grey;
    
}
.DeleteAcc__Modal__Heading{
    margin-bottom: 1rem;
}
.UserDetail__Block{
    text-align: center;
    margin: 2rem 0;
    .Profile__Image{
        width:100px;
        border-radius: 50%;
            overflow: hidden;
    }
    .UserDetail__Para{
        font-family: "Poppins";
        font-weight: 500;
        font-size: 22px;
        text-transform: capitalize;
    }
    .UserDetail__Text{
        font-family: "Poppins";
            color: grey;
    }
}
.DeleteAcc__Modal__Delete__Btn{
    width: 100%;
    padding: 0.7rem;
    color: #fff;
    font-size: 15px;
    font-family: "Poppins";
    font-weight: 400;
    background-color: #D71513;
    border-radius: 8px;
    margin-bottom: 0.8rem;
}
.DeleteAcc__Modal__Cancel__Btn{
    width: 100%;
        padding: 0.6rem;
        color: #111;
        font-size: 15px;
        font-family: "Poppins";
        font-weight: 400;
        border-radius: 8px;
        border: 2px solid #D0D5DD;
}
.Delete__Modal__Cross__Icon{
    margin-left: auto;
    width: 45px;
        height: 26px;
        margin-top: 0.7rem;
        cursor:pointer;
        
}

//Change password Styles
.Input__Field__Label {
  font-weight: 500;
  font-family: "Poppins";
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}
.Input__Container{
    position: relative;
    margin-bottom: 0.6rem;
    input:focus{
        outline: none;
    }
    input::placeholder{
        font-weight: 400;
        font-family: "Poppins";
        font-size: 15px;
        color:#667085
    }
    // .Input__Field{
       
    //         margin: 0;
    // }
    
    .Input__Eye__Icon{
        position: absolute;
        top:7px;
        right: 13px;
        color: #667085
        }
}

.Input__Field{
    border:2px solid #D0D5DD ;
    width:100%;
    border-radius: 7px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 400;
        font-family: "Poppins";
        font-size: 15px;
         margin: 0;
}
.Error__Field {
    border: 2px solid #D71513;
        width: 100%;
        border-radius: 7px;
        padding: 0.5rem;
        margin-bottom: 1rem;
        font-weight: 400;
        font-family: "Poppins";
        font-size: 15px;
        margin: 0;
}